import { api } from "../api";
import { createSlice, Slice } from "@reduxjs/toolkit";

const initialState: any = {
  showIntelModal:false,
};

const slice: Slice = createSlice({
  name: "requestIntelModal",
  initialState,
  reducers: {
    setShowIntelModal: (state) => {
        return {
          ...state,
          showIntelModal:!state.showIntelModal,
        };
      },
  },
});

const { reducer, actions } = slice;
export const { setShowIntelModal } = actions;
export default reducer;
