import React from 'react';
import { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import MsTeamBotOAuth from '../components/modules/pages/OAuth/MsTeamsBot';
const PageNotFound = lazy(
  () => import('../components/modules/pages/PageNotFound'),
);
const BattleCard = lazy(() => import('../Company/BattleCard/BattleCard'));
const CompetitiveMatrix = lazy(
  () => import('../Company/CompetitiveMatrix/CompetitiveMatrix'),
);
const MindMap = lazy(
  () => import('../Company/IntelReportAndProjects/MindMap/MindMap'),
);
const IntelReportHeader = lazy(
  () => import('../Company/IntelReportAndProjects/IntelReportHeader'),
);
const SlackPage = lazy(() => import('../components/modules/pages/OAuth/Slack'));
const Pages = lazy(() => import('../components/modules/pages'));
const ResetPassword = lazy(
  () => import('../components/modules/Auth/ResetPassword'),
);
const CompanyDashboard = lazy(
  () => import('../Company/CompanyDashboard/CompanyDashboard'),
);
const CompanyAdminTowsAnalysis = lazy(
  () => import('../Company/TowsAnalysis/CompanyAdminTowsAnalysis'),
);
const MasterSetting = lazy(
  () => import('../Company/MasterSetting/MasterSetting'),
);
const KeyIntelligenceTopic = lazy(() => import('../Company/KITKIQ/KIT'));
const IntelReportAndProjects = lazy(
  () => import('../Company/IntelReportAndProjects/IntelReportAndProjects'),
);
const IntelReportOrProjectDetail = lazy(
  () => import('../Company/IntelReportAndProjects/IntelReportOrProjectDetail'),
);

const TeamMembers = lazy(() => import('../Company/TeamMembers/TeamMembers'));
const PrimaryIntelCompanyAdmin = lazy(
  () => import('../Company/PrimaryIntel/PrimaryIntelCompanyAdmin'),
);
const OpenSourceCompanyAdmin = lazy(
  () => import('../Company/OpenSource/OpenSourceCompanyAdmin'),
);
const CompanyAdminOpenSourceDetail = lazy(
  () => import('../Company/OpenSource/OpenSourceDetailsCompanyAdmin'),
);
const CompanyAdminOpenSourceDetailParent = lazy(
  () => import('../Company/OpenSource/OpenSourceDetailsParent'),
);
const CompanyAdminDetailParent = lazy(
  () => import('../Company/PrimaryIntel/CompanyAdminDetailParent'),
);
const CompanyAdminDetail = lazy(
  () => import('../Company/PrimaryIntel/CompanyAdminDetail'),
);
const SecondaryIntel = lazy(
  () => import('../Company/SecondaryIntel/SecondaryIntel'),
);
const DetailsIntel = lazy(
  () => import('../components/modules/pages/intelDetail'),
);
const Login = lazy(() => import('../components/modules/Auth/Login'));
const ForgotPassword = lazy(
  () => import('../components/modules/Auth/ForgotPassword'),
);
const Auth = lazy(() => import('../components/modules/Auth'));
const Otp = lazy(() => import('../components/modules/Auth/Otp'));
const OtpSent = lazy(() => import('../components/modules/Auth/OtpSent'));

const Routes2 = () => {
  let routes = useRoutes([
    {
      path: '/',
      element: <Navigate replace to="/auth" />,
    },
    {
      path: '/auth/login/ms-teams-bot/oauth',
      element: <MsTeamBotOAuth />,
    },
    {
      path: '/auth/login/slack-access',
      element: <SlackPage />,
    },
    {
      path: 'auth',
      element: <Auth />,
      children: [
        { path: '/auth', element: <Navigate replace to="login" /> },
        { path: 'login', element: <Login /> },
        {
          path: 'forgot-password',
          element: <ForgotPassword />,
        },
        { path: 'otp', element: <Otp /> },
        { path: 'otp-sent', element: <OtpSent /> },
      ],
    },
    { path: '*', element: <PageNotFound /> },
    { path: '/employee/*', element: <Navigate to="/company/home" /> },
    { path: '/admin/*', element: <Navigate to="/company/home" /> },
    {
      path: '/company',
      element: <Pages />,
      children: [
        { path: '/company', element: <Navigate replace to="home" /> },
        { path: 'reset-password', element: <ResetPassword /> },
        {
          path: 'home',
          element: (
            <div>
              <CompanyDashboard />
            </div>
          ),
        },
        { path: 'details', element: <DetailsIntel /> },
        {
          path: 'view-intelligence',
          element: (
            <PrimaryIntelCompanyAdmin
              isSource={true}
              isSearch={true}
              isHeading={true}
            />
          ),
          children: [],
        },
        {
          path: 'view-open-source',
          element: (
            <OpenSourceCompanyAdmin
              isSource={false}
              isSearch={true}
              isHeading={true}
            />
          ),
          children: [],
        },
        {
          path: 'view-intelligence',
          element: <CompanyAdminDetailParent />,
          children: [{ path: ':id', element: <CompanyAdminDetail /> }],
        },
        {
          path: 'view-open-source',
          element: <CompanyAdminOpenSourceDetailParent />,
          children: [{ path: ':id', element: <CompanyAdminOpenSourceDetail /> }],
        },
        {
          path: 'show-competitor-dashboard',
          element: <SecondaryIntel />,
        },
        {
          path: 'report',
          element: <IntelReportHeader />,
        },
        {
          path: 'report/view-report',
          element: <Outlet />,
          children: [{ path: ':id', element: <IntelReportOrProjectDetail /> }],
        },
        {
          path: 'report/view-mind-map',
          element: <Outlet />,
          children: [{ path: ':id', element: <MindMap /> }],
        },
        { path: 'battle-card', element: <BattleCard /> },
        { path: 'competitive-matrix', element: <CompetitiveMatrix /> },
        { path: 'tows', element: <CompanyAdminTowsAnalysis /> },
        {
          path: 'intelligence-topic',
          element: <KeyIntelligenceTopic />,
        },
        { path: 'view-member', element: <TeamMembers /> },
        {
          path: 'mobile-application-options',
          element: <MasterSetting />,
        },
      ],
    },
  ]);
  return routes;
};

export default Routes2;
