import { api } from "../api";
import { createSlice, Slice } from "@reduxjs/toolkit";

const initialState: any = {
  masterSettings: {
    collection_methods: [],
    distributions: [],
    sources: [],
    hashtags:[],
  },
  filteredSetting: [],
  total: {
    total_collection_methods: 0,
    total_distributions: 0,
    total_sources: 0,
    total_hashtags:0
  },
};

const slice: Slice = createSlice({
  name: "masterSetting",
  initialState,
  reducers: {
    fetchCollectionById: (state, payload: any) => {
      return {
        ...state,
        filteredSetting: [
          ...state.masterSettings[payload.payload.itemType],
        ].filter((item) => item.id === payload.payload.id),
      };
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      api.endpoints.getAllMaterSettingsCompany.matchFulfilled,
      (state, { payload }) => {
        if (payload.data.hasOwnProperty("collection_methods")) {
          state.masterSettings.collection_methods =
            payload.data.collection_methods;
          state.total.total_collection_methods =
            payload.data.total_collection_methods;
        }
        if (payload.data.hasOwnProperty("distributions")) {
          state.masterSettings.distributions = payload.data.distributions;
          state.total.total_distributions = payload.data.total_distributions;
        }
        if (payload.data.hasOwnProperty("sources")) {
          state.masterSettings.sources = payload.data.sources;
          state.total.total_sources = payload.data.total_sources;
        }
        if (payload.data.hasOwnProperty("hashtags")) {
          state.masterSettings.hashtags = payload.data.hashtags;
          state.total.total_hashtags = payload.data.total_hashtags;
        }
      }
    );
  },
});

const { actions, reducer } = slice;
export const { fetchCollectionById } = actions;

export default reducer;
