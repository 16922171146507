import { api } from "../api";
import { createSlice, Slice } from "@reduxjs/toolkit";

const initialState: any = {
  teamMembers: [],
  totalItems: 0,
  filteredTeamMember: [],
};

const slice: Slice = createSlice({
  name: "companyTeamMembers",
  initialState,
  reducers: {
    fetchTeamMemberById: (state, { payload }) => {
      return {
        ...state,
        filteredTeamMember: [...state.teamMembers].filter(
          (team) => team.id === payload
        ),
      };
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      api.endpoints.getTeamMembersCompany.matchFulfilled,
      (state, { payload }) => {
        state.teamMembers = payload.data.employees;
        state.totalItems = payload.data.total_employees;
      }
    );
  },
});

const { actions, reducer } = slice;
export const { fetchTeamMemberById } = actions;
export default reducer;
