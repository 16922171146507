import { api } from "../api";
import { createSlice, Slice } from "@reduxjs/toolkit";

const initialState: any = {
  tags: [],
  total_hashtags:0
};

const slice: Slice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    getAllTags: (state, payload) => {
      return {
        ...state,
        tags: [...state],
      };
    },

  },
  extraReducers(builder) {
    builder.addMatcher(
      api.endpoints.getAllHashtags.matchFulfilled,
      (state, { payload }) => {
        state.tags = payload.data.hashtags;
        state.total_hashtags=payload.data.total_hashtags;
      }
    );
  },
});

const { reducer, actions } = slice;
export const { getAllTags } = actions;
export default reducer;
