import { api } from "../api";
import { createSlice, Slice } from "@reduxjs/toolkit";

const initialState: any = {
  allKits: [],
};

const slice: Slice = createSlice({
  name: "kitsSlice",
  initialState,
  reducers: {

    getAllKitsEmployee: (state, payload) => {
      return {
        ...state,
        allKits: [...state],
      };
    },

  },
  extraReducers(builder) {
    builder.addMatcher(
      api.endpoints.getAllKits.matchFulfilled,
      (state, { payload }) => {
        state.allKits = payload.data.kits;
      }
    );
    // builder.addMatcher(
    //   api.endpoints.getAllHashtags.matchFulfilled,
    //   (state, { payload }) => {
    //     state.tags = payload.data.hashtags;
    //     state.total_hashtags=payload.data.total_hashtags;
    //   }
    // );
  },
});

const { reducer, actions } = slice;
export const { getAllTags } = actions;
export default reducer;
