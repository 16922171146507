import React, { Suspense, useEffect, useState } from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
// https://fluentsite.z22.web.core.windows.net/quick-start
import { Provider, teamsTheme, Loader } from '@fluentui/react-northstar';
import { HashRouter as Router } from 'react-router-dom';
import { useTeamsFx } from './sample/lib/useTeamsFx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import './App.css';
import RoutesComponent from '../Routes/Routes';
import FullPageSpinner from './shared/Spinner/FullPageSpinner';
import { onMessageListener } from '../firebaseInit';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { setIsNotificationUpdated } from '../redux/notification/notificationReducer';
import { useSelector } from 'react-redux';
import Routes2 from '../Routes/Routes2';
import Routes3 from '../Routes/Routes3';

toast.configure();

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const { theme } = useTeamsFx();
  const [notification, setNotification] = useState({ title: '', body: '' });
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.loginReducers);
  const { name } = state;

  onMessageListener(setNotification)
    .then((payload: any) => {})
    .catch((err: any) => console.log('failed: ', err));

  useEffect(() => {
    if (notification.body.trim() !== '') {
      toast.success(notification?.body?.split('[[')[0]);
      dispatch(setIsNotificationUpdated({}));
    }
  }, [notification]);
  const type = useSelector((state: any) => state?.loginReducers?.userType);

  return (
    <Provider
      theme={theme || teamsTheme}
      styles={{ backgroundColor: '#eeeeee' }}
    >
      <Suspense fallback={<FullPageSpinner />}>
        <Router>
          {type === 1 || type === '' || type === null || type === undefined ? (
            <RoutesComponent />
          ) : type === 2 ? (
            <Routes2 />
          ) : (
            <Routes3 />
          )}
        </Router>
        {name ? (
          <CookieConsent
            location="bottom"
            buttonText="I agree"
            cookieName="myAwesomeCookieName2"
            style={{ background: '#2B373B', zIndex: 99999 }}
            buttonStyle={{
              color: '#fff',
              backgroundColor: '#FF833b',
              fontSize: '13px',
              position: 'relative',
              right: '480%',
            }}
            expires={150}
          >
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              Do you like the cookies? We use cokkies to ensure you get the best
              experience on our website.
            </div>
          </CookieConsent>
        ) : null}
      </Suspense>
    </Provider>
  );
}
