import { api } from "./../api";
import { createSlice, Slice } from "@reduxjs/toolkit";

import { AdminCompanyState } from "../types";

const initialState: AdminCompanyState = {
  companies: [],
  totalItems: 0,
  filteredCompany: [],
};

const slice: Slice = createSlice({
  name: "adminCompany",
  initialState,
  reducers: {
    fetchCompanyAdminById: (state, { payload }) => {
      return {
        ...state,
        filteredCompany: [...state.companies].filter(
          (company) => company.id === payload
        ),
      };
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      api.endpoints.getAllCompanyAdmin.matchFulfilled,
      (state, { payload }) => {
        state.companies = payload.data.companies;
        state.totalItems = payload.data.total_companies;
      }
    );
  },
});

const { actions, reducer } = slice;
export const { fetchCompanyAdminById } = actions;
export default reducer;
