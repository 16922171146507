import { Loader } from "@fluentui/react-northstar";
import ReactDOM from "react-dom";
import style from "./FullPageSpinner.module.css";
const FullPageSpinner = () => {
  return ReactDOM.createPortal(
    <div className={style.container}>
      <div>
        <Loader />
      </div>
    </div>,
    document.getElementById("loader")!
  );
};

export default FullPageSpinner;
