import { combineReducers } from "redux";
import {
  adminCompanyReducer,
  api,
  authReducer,
  companyTeamMemberReducer,
  distributionReducer,
  modifiedOptionReducer,
  masterSettingReducer,
  kitReducer,
  tagsReducer,
  quickReportReducer,
  kitSliceReducer,
  kiqSliceReducer,
  primaryIntelReducer,
  addToNodeReducer,
  requestIntelModalReducer,
  notificationReducer
} from "./actions/action.creator";

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  loginReducers: authReducer,
  adminCompanyReducer,
  companyTeamMemberReducer,
  distributionReducer,
  modifiedOptionReducer,
  masterSettingReducer,
  kitReducer,
  tagsReducer,
  quickReportReducer,
  kitSliceReducer,
  kiqSliceReducer,
  primaryIntelReducer,
  addToNodeReducer,
  requestIntelModalReducer,
  notificationReducer
});
export default rootReducer;
