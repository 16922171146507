import {
  configureStore,
  ConfigureStoreOptions,
  EnhancedStore,
} from "@reduxjs/toolkit";

import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducers from "./reducers";
import middleware from "./middleware";
import { api } from "./api";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["loginReducers", "quickReportReducer"],
  blacklist: [api.reducerPath],
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

function createStore(
  options?: ConfigureStoreOptions["preloadedState"] | undefined
): EnhancedStore {
  return configureStore({
    reducer: persistedReducer,
    ...middleware,
    ...options,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(api.middleware),
  });
}

const store: EnhancedStore = createStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
