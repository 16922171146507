import { createSlice, Slice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AuthState } from "../types";

const initialState: AuthState = {
  user: null,
  access_token: null,
  userType: "",
  name: "",
  email: "",
  id: "",
};

const userTypeMapping: any = {
  1: "admin",
  2: "company",
  3: "employee",
};

const slice: Slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logoutAction: (): AuthState => initialState,
    loginAction: (state: any, action: any) => {
      const {
        access_token,
        type,
        name,
        email,
        id,
      } = action.payload.data.data;
      state.access_token = access_token;
      state.userType = type;
      state.name = name;
      state.email = email;
      state.id = id;
      state.user = userTypeMapping[type] || "";
      toast.success(action.payload.data.message);
    },
    changePassword: (): AuthState => initialState,
    renewToken: (state, action) => {
      return action.payload;
    }
  }
});
const { actions, reducer } = slice;
export const { loginAction, logoutAction, renewToken } = actions;
export default reducer;
