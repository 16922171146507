import { api } from "../api";
import { createSlice, Slice } from "@reduxjs/toolkit";

const initialState: any = {
  distributions: [],
  totalItems: 0,
  modifiedOption: [],
};

const slice: Slice = createSlice({
  name: "distribution",
  initialState,
  reducers: {
    modifiedOptionReducer: (state) => {
      let a: any = [];
      state.distributions.forEach((element: any) => {
        a.push({ value: element.id, label: element.name });
      });
      state.modifiedOption = a;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      api.endpoints.getAllDistribution.matchFulfilled,
      (state, { payload }) => {
        state.distributions = payload.data.distributions;
        state.totalItems = payload.data.total_distributions;
      }
    );
  },
});

const { actions, reducer } = slice;
export const { modifiedOptionReducer } = actions;
export default reducer;
