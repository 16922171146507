import React from 'react';
import { lazy } from 'react';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import MsTeamBotOAuth from '../components/modules/pages/OAuth/MsTeamsBot';
const Pages = lazy(() => import('../components/modules/pages'));
const BattleCardEmployee = lazy(
  () => import('../employees/BattleCardEmployee/BattleCardEmployee'),
);
const MindMapEmployee = lazy(() => import('../employees/MindMapEmployee'));
const CompetitiveMatrixEmployee = lazy(
  () =>
    import('../employees/CompetitiveMatrixEmployee/CompetitiveMatrixEmployee'),
);
const BattleCardDetailPage = lazy(
  () => import('../employees/BattleCardEmployee/BattleCardDetailPage'),
);
const MatrixDetail = lazy(
  () => import('../employees/CompetitiveMatrixEmployee/MatrixDetail'),
);
const IntelReportsEmployeeHeader = lazy(
  () => import('../employees/IntelReportsEmployeeHeader'),
);
const PageNotFound = lazy(
  () => import('../components/modules/pages/PageNotFound'),
);
const ResetPassword = lazy(
  () => import('../components/modules/Auth/ResetPassword'),
);
const Tags = lazy(() => import('../employees/Tags'));
const TowsAnalysis = lazy(() => import('../employees/TowsAnalysis'));
const StrategicInitiatives = lazy(
  () => import('../employees/StrategicInitiatives'),
);
const StrategicIniativeparent = lazy(
  () => import('../employees/StrategicIniativeparent'),
);
const ReportParent = lazy(() => import('../employees/ReportParent'));
const Report = lazy(() => import('../employees/Report'));
const MyRfis = lazy(() => import('../employees/MyRfis'));
const MyRFIDetail = lazy(() => import('../employees/MyRFIDetail'));
const MyIntelTable = lazy(() => import('../employees/MyIntelTable'));
const IntelReports = lazy(() => import('../employees/IntelReports'));
const IntelOfInterest = lazy(() => import('../employees/IntelOfInterest'));
const DashboardContainer = lazy(
  () => import('../employees/DashboardContainer'),
);
const Dashboard = lazy(() => import('../employees/Dashboard'));
const DetailsIntel = lazy(
  () => import('../components/modules/pages/intelDetail'),
);
const PrimaryIntel = lazy(
  () => import('../components/modules/pages/PrimaryIntel'),
);

const PrimaryIntelDetails = lazy(
  () => import('../components/modules/pages/PrimaryIntelDetails'),
);
const IntelDetailsParent = lazy(
  () => import('../components/modules/pages/IntelDetailsParent'),
);
const CompanyAdminOpenSourceDetails = lazy(() => import('../Company/OpenSource/OpenSourceDetailsCompanyAdmin'));
const EmployeeOpenSource = lazy(() => import('../Company/OpenSource/OpenSourceCompanyAdmin'));

const Login = lazy(() => import('../components/modules/Auth/Login'));
const ForgotPassword = lazy(
  () => import('../components/modules/Auth/ForgotPassword'),
);
const Auth = lazy(() => import('../components/modules/Auth'));
const Otp = lazy(() => import('../components/modules/Auth/Otp'));
const OtpSent = lazy(() => import('../components/modules/Auth/OtpSent'));

const Routes3 = () => {
  let routes = useRoutes([
    {
      path: '/',
      element: <Navigate replace to="/auth" />,
    },
    {
      path: '/auth/login/ms-teams-bot/oauth',
      element: <MsTeamBotOAuth />,
    },
    {
      path: 'auth',
      element: <Auth />,
      children: [
        { path: '/auth', element: <Navigate replace to="login" /> },
        { path: 'login', element: <Login /> },
        {
          path: 'forgot-password',
          element: <ForgotPassword />,
        },
        { path: 'otp', element: <Otp /> },
        { path: 'otp-sent', element: <OtpSent /> },
      ],
    },
    { path: '*', element: <PageNotFound /> },
    { path: '/company/*', element: <Navigate to="/employee/home" /> },
    { path: '/admin/*', element: <Navigate to="/employee/home" /> },
    {
      path: '/employee',
      element: <Pages />,
      children: [
        { path: '/employee', element: <Navigate replace to="home" /> },
        { path: 'reset-password', element: <ResetPassword /> },
        {
          path: 'home',
          element: <DashboardContainer />,
          children: [
            { path: '', element: <Dashboard /> },
            { path: 'my-intel', element: <MyIntelTable /> },
            {
              path: 'my-rfis',
              element: (
                <>
                  <Outlet />
                </>
              ),
              children: [
                { path: '', element: <MyRfis /> },
                {
                  path: ':id',
                  element: <MyRFIDetail />,
                },
              ],
            },
            { path: 'intel-of-interest', element: <IntelOfInterest /> },
            {
              path: 'view-intel',
              element: (
                <>
                  <Outlet />
                </>
              ),
              children: [
                {
                  // path: ':id',
                  element: <MyRFIDetail />,
                },
                {
                  path: ':id',
                  element: <PrimaryIntelDetails />,
                },
              ],
            },
          ],
        },

        { path: 'details', element: <DetailsIntel /> },
        {
          path: 'view-intelligence',
          element: (
            <PrimaryIntel isSource={true} isSearch={true} isHeading={true} />
          ),
          children: [],
        },
        {
          path: 'view-open-source',
          element: (<EmployeeOpenSource isSource={true} isSearch={true} isHeading={true} />),
          children: []
        },
        {
          path: 'view-open-source',
          element: <CompanyAdminOpenSourceDetails />,
          children: [{ path: ':id', element: <CompanyAdminOpenSourceDetails /> }]
        },
        {
          path: 'view-intelligence',
          element: <IntelDetailsParent />,
          children: [{ path: ':id', element: <PrimaryIntelDetails /> }]
        },
        {
          path: 'show-competitor-dashboard',
          element: <div>Second Intelligence</div>,
        },
        {
          path: 'report',
          element: <IntelReportsEmployeeHeader />,
          children: [],
        },
        {
          path: 'report/view-report',
          element: <ReportParent />,
          children: [{ path: ':id', element: <Report /> }],
        },
        {
          path: 'report/view-mind-map',
          element: <Outlet />,
          children: [{ path: ':id', element: <MindMapEmployee /> }],
        },
        {
          path: 'competitive-matrix',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <CompetitiveMatrixEmployee />,
            },
            {
              path: '/employee/competitive-matrix/:id',
              element: <MatrixDetail />,
            },
          ],
        },
        // { path: 'battle-card', element: <BattleCardEmployee /> },
        {
          path: 'battle-card',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <BattleCardEmployee />,
            },
            {
              path: '/employee/battle-card/:id',
              element: <BattleCardDetailPage />,
            },
          ],
        },
        {
          path: 'tows',
          element: <TowsAnalysis />,
          children: [],
        },
        {
          path: 'view-tows',
          element: <StrategicIniativeparent />,
          children: [{ path: ':id', element: <StrategicInitiatives /> }],
        },
        {
          path: 'tags',
          element: <Tags />,
        },
      ],
    },
  ]);
  return routes;
};

export default Routes3;
