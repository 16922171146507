import { createSlice, Slice } from "@reduxjs/toolkit";
import { nodeInterface } from "../types";

const initialState: nodeInterface = {
  desc: "",
  isOpen: false,
  id: "",
  color: "",
  title:"",
  parentNode: null,
  isEdit: false,
  sourceNode: null,
};

const slice: Slice = createSlice({
  name: "addNode",
  initialState,
  reducers: {
    addDataToNode: (state, { payload }) => {
      return {
        ...state,
        isOpen: true,
        ...payload,
      };
    },
  },
});

const { actions, reducer } = slice;
export const { addDataToNode } = actions;
export default reducer;
