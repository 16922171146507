import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import persistStore from 'redux-persist/es/persistStore';
import { Provider } from 'react-redux';
import App from './components/App';
import './index.css';
import './assets/styles/main.scss';
import './assets/styles/_common.scss';
import './assets/styles/_variable.scss';

import store from './redux/store';
import 'bootstrap/dist/css/bootstrap.min.css';

const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
