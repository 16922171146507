import { api } from "./actions/action.creator";
/**
 * Adding the api middleware enables caching, invalidation, polling,
 * and other useful features of `rtk-query`.
 */
const middleware = {
  // eslint-disable-next-line
  middleware: (getDefaultMiddleware: any): any =>
    getDefaultMiddleware().concat(api.middleware),
};
export default middleware;
