import axios from 'axios';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { adaptLogo2 } from '../../../../../assets/Logos';
import { useAppSelector } from '../../../../../hooks/redux';
import FullPageSpinner from '../../../../shared/Spinner/FullPageSpinner';
import './msTeamBot.scss';

const MsTeamBotOAuth = () => {
  const apiRoot = process.env.REACT_APP_API_ROOT;

  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, isLoading] = useState(false);
  const [respText, setRespText] = useState('');
  const userState = useAppSelector((state) => state.loginReducers);

  useEffect(() => {
    postMsOAuth();
  }, []);

  const postMsOAuth = async () => {
    isLoading(true);
    const code = searchParams.get('code');
    const state: any = searchParams.get('state');
    const data: any = {
      body: {
        code,
      },
    };
    const dataSlack: any = {
      body: {
        code,
        state,
      },
    };

    if (code) {
      try {
        if (state?.length > 15) {
          const res = await axios.post(
            `${apiRoot}${
              state === 'salesforce' || state === 'slack'
                ? state
                : state?.length > 15
                ? 'slack'
                : 'ms-teams-bot'
            }/oauth`,
            dataSlack.body,
            {
              headers: {
                Authorization: userState.access_token,
              },
            },
          );
          setRespText(res?.data?.message);
        } else {
          const res = await axios.post(
            `${apiRoot}${
              state === 'salesforce' || state === 'slack'
                ? state
                : 'ms-teams-bot'
            }/oauth`,
            data.body,
            {
              headers: {
                Authorization: userState.access_token,
              },
            },
          );
          setRespText(res?.data?.message);
        }
      } catch (error: any) {
        setRespText(error?.response?.data?.message || 'Authentication Failed');
      }
    } else {
      setRespText(
        'This account is not authorized to perform this action. Please try with some other account.',
      );
    }
    isLoading(false);
  };

  return (
    <div className="ms-team-container">
      <div
        className="header-logo"
        role="button"
        onClick={() => (window.location.href = 'https://adaptintel.com')}
      >
        <img src={adaptLogo2} alt="Logo" width="250" height="100" />
      </div>
      {loading ? (
        <FullPageSpinner />
      ) : (
        <div className="oauth-result">
          <p> {respText} </p>
        </div>
      )}
    </div>
  );
};

export default MsTeamBotOAuth;
