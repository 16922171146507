import { api } from "../api";
import { createSlice, Slice } from "@reduxjs/toolkit";

const initialState: any = {
  kits: [],
  totalItems: 0,
  question: {},
  filteredKIQ: [],
};

const slice: Slice = createSlice({
  name: "kits",
  initialState,
  reducers: {
    getKIQsById: (state, payload) => {
      return {
        ...state,
        filteredKIQ: [...state.kits]
          .filter((item) => item.id === payload.payload.topicId)[0]
          .kiqs.filter((i: any) => i.id === payload.payload.questionId),
      };
    },
    resetKIQsById: (state) => {
      return {
        ...state,
        filteredKIQ: [],
      };
    },
    // filter((i) => i.id === payload.payload.questionId)
  },
  extraReducers(builder) {
    builder.addMatcher(
      api.endpoints.getAllKITopic.matchFulfilled,
      (state, { payload }) => {
        state.kits = payload.data.kits;
        state.totalItems = payload.data.total_kits;
      }
    );
    builder.addMatcher(
      api.endpoints.getKIQuestion.matchFulfilled,
      (state, { payload }) => {
        state.kits.forEach((item: any) => {
          if (item.id === payload.data.kit.id) item.kiqs = payload.data.kiqs;
        });
      }
    );
  },
});

const { reducer, actions } = slice;
export const { getKIQsById, resetKIQsById } = actions;
export default reducer;
