import { api } from "../api";
import { createSlice, Slice } from "@reduxjs/toolkit";

const initialState: any = {
  allPrimaryIntel: [],
  totalIntels: 0,
};

const slice: Slice = createSlice({
  name: "primaryIntel",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addMatcher(
      api.endpoints.getAllPrimaryIntel.matchFulfilled,
      (state, { payload }) => {
        state.allPrimaryIntel = payload.data.intels;
        state.totalIntels = payload.data.total_intels;
      }
    );
  },
});

const { reducer } = slice;
export default reducer;
